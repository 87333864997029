import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import PortableTextBlock from '@components/sanity/portableTextBlock';
import BlogCard from '@components/blog/blogCard';
import CategoryMenu from '@components/blog/categoryMenu';

const BlogCategory = ({ data: { page, posts } }) => {
  return (
    <Layout isBlog>
      <div className="px-gutter pt-space-m text-center grid gap-y-[18px]">
        <h1 className="text-3xl text-red md:text-5xl lg:text-6xl">{page?.title}</h1>
        <PortableTextBlock text={page?.description} className="max-w-3xl mx-auto" />
      </div>
      {/* Category Nav */}
      <CategoryMenu active={page?.title} />
      {/* Post Grid */}
      <div className="grid gap-10 md:gap-y-20 md:grid-cols-3 px-gutter pb-space-m mt-8 md:mt-0">
        {posts?.nodes.map((item) => (
          <BlogCard {...item} />
        ))}
      </div>
    </Layout>
  );
};

export default BlogCategory;

export const pageQuery = graphql`
  query blogCatQuery($skip: Int!, $limit: Int!, $id: String!, $slug: String!) {
    page: sanityBlogCategory(slug: { current: { eq: $slug } }) {
      title
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
    }
    posts: allSanityBlogPost(
      skip: $skip
      limit: $limit
      filter: { category: { _id: { eq: $id } } }
    ) {
      nodes {
        key: _key
        title
        excerpt
        isNewsletter
        url
        postDate(formatString: "D MMM YYYY")
        cardImage {
          ...ImageWithPreview
        }
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
